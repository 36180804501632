<div  class="modal-body modal-bodylogin mb-3 d-flex flex-column justify-content-center">
    <div>
      <form [formGroup]="passwordForm" (submit)="resetPassword()">
        <div class="d-flex align-items-center">
          <input formControlName ="email" class="form-control input-login mb-2" type="email" placeholder="Email" required>
          <span class="text-danger" >*</span>
        </div>
        <div class="d-flex  justify-content-center" 
                    *ngIf="passwordForm.get('email')?.invalid && (passwordForm.get('email')?.dirty || passwordForm.get('email')?.touched)">
                    <small class="text-danger">Email no válido</small>
        </div>
        <button class="btn btn-agendamiento" [disabled]=!passwordForm.valid>
          <span *ngIf="isloading"> <app-spinner color="text-light" /> </span>
          <span *ngIf="!isloading">Recuperar</span>
        </button>
      </form>
    </div>
  </div>
       
