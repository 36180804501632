
<nav class="navbar menu navbar-expand-lg menu">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="home"><img src="../../../../assets/img/Horizontal_blanco@300x-8.png" class="logo.png" width="250px" alt="logo KGM"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        (click)="toggleMenu()"
      >
        <span class="navbar-toggler-icon border border-light bg-light rounded "></span>
      </button>
      <div class="collapse navbar-collapse mb-3 justify-content-end rounded" id="navbarSupportedContent"  [ngClass]="{ 'show': isMenuOpen }">
        <ul class="navbar-nav mb-2 mb-lg-0 mt-2" style="background-color:  #3F3953;">
          
          <li class="nav-item">
            <a class="nav-link active item" [href]="nuestros_vehiculos | secureUrl" target="_blank">Portafolio vehículos</a>
          </li>

          <li class="nav-item">
            <a class="nav-link agenda item" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-disabled="true" (click)="toggleMenu()">Agenda tu mantenimiento</a>
          </li>
          
          <li class="nav-item">
            <a class="nav-link active item" aria-current="page" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalvideo" (click)="toggleMenu()">¿Cómo comprar?</a>
          </li>
          
          <li class="nav-item" *ngIf="authService.islogIn$|async">
            <div style="display: flex; flex-direction: row; justify-content:center ; align-items: center;">
              <div style="display: flex;justify-content: center; 
                          align-items: center; width: 30px; height: 30px; background-color: #6200ea; color: #ffffff; 
                          font-weight: bold; font-size: 20px; border-radius: 50%;">
                {{nombreAvatar}}
              </div>
              <a class="nav-link agenda  item" (click)="logout()" aria-disabled="true" style="margin-left: 0px;padding-left: 0px;">Cerrar sesión</a>
            </div>
          </li>
          
          <li class="nav-item" *ngIf="!(authService.islogIn$|async)">
            <a class="nav-link agenda  item" data-bs-toggle="modal" data-bs-target="#login" aria-disabled="true"  (click)="toggleMenu()">Inicia sesión</a>
          </li>
          
        </ul>
        <div class="d-flex pb-1 pt-3"  style="background-color: #3F3953; margin-top: -8px;">
          <a class="nav-link agenda  item cursor-pointer" routerLink="/checkout" aria-disabled="true">
            <img src="../../../../assets/img/carritodecompras.webp" alt="carrito" >
          </a>
          <span class="top-1 start-100 translate-middle badge rounded-pill bg-light ms-3 mt-2" style="padding-top: 5px; color: #3F3953"  >
            {{ carrocompraService.cant_carrito$ | async }}
          </span>
        </div>
      </div>
    </div>
  </nav>

 <!--formulario de agendamiento-->
<div class="modal fade modal-agendamiento" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog h-75">
    <div class="modal-content h-75 w-100">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="exampleModalLabel">Agenda tu mantenimiento</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
      </div>
      <div class="modal-body modal-bodyagendamiento mb-3">
        <app-form-agendamiento />
      </div>
    </div>
  </div>
</div>


<!--formulario de Login-->
<div #myModal class="modal fade modal-login" id="login" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog h-100">
  <div class="modal-content d-flex flex-column h-75 pb-5">
    <div class="modal-header">
      <h1 class="title_modalogin text-center">Bienvenido a nuestra tienda virtual</h1>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>


    <div  class="modal-body modal-bodylogin mt-5 mb-3 d-flex flex-column justify-content-center">
      <div class="mt-2">
        <form [formGroup]="form" (submit)="Login()">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <input formControlName ="log" class="form-control input-login mb-1" type="email" id="log" name="login" placeholder="Email" required>
            <input formControlName ="password" class="form-control input-login mb-2" type="password" id="password" name="password" placeholder="Contraseña" required>
            <button class="btn btn-agendamiento mx-5 mt-3" [disabled]=!form.valid>
              <span *ngIf="isloading"> <app-spinner color="text-light" /> </span>
              <span *ngIf="!isloading"> Iniciar Sesion </span>
            </button>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-center flex-column mt-4">
        <a href="#" class="text-center mb-3" data-bs-toggle="modal" data-bs-target="#recordar"  aria-label="Close" data-bs-dismiss="modal">¿Olvidaste la contraseña?</a>
        <!-- <a href="home.component.html" class="text-center mb-3">¿olvidaste la contraseña?</a> -->
        <p class="text-center mb-2"> ¿Aún no tienes cuenta?</p>
        <div class="d-flex justify-content-center">
          <a type="button" class="btn btn-registro mb-2" data-bs-toggle="modal" data-bs-target="#registro"  aria-label="Close" data-bs-dismiss="modal">Crear una cuenta</a>
        </div>
      </div>
      
    </div>
         
  </div>
</div>
</div>

<!--formulario de registro-->
<div #myModals class="modal fade bd-example-modal-lg" id="registro" tabindex="-1"  aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="title_modalogin text-center">Registrar Usuario</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <app-registarse  />
    </div>
  </div>
</div>

<!--formulario de recordar password-->
<div #modalRecordar class="modal fade bd-example-modal-lg" id="recordar" tabindex="-1"  aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="title_modalogin text-center">Recordar Contraseña</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <app-recodar-password  />
    </div>
  </div>
</div>

<!-- Modal video -->
<div class="modal fade" id="exampleModalvideo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cerrarmodal()"></button>
      </div>
      <div class="modal-body">
        <iframe id="#four-seasons-video" type="text/html" width="460" height="360"
        [src]="como_comprar | secureUrl"
        frameborder="0"></iframe>
      </div>
     
    </div>
  </div>
</div>