<!-- TODO:validar los tipos de identificacion -->
<div class="d-flex mb-5 mt-5">
    <form [formGroup]="formCliente" (ngSubmit)="onSubmit()" class="d-flex flex-column">
        <div>
            <div class="col-sm">
                <select formControlName="t_Identificacion" class="form-select input-registro mb-2">
                    <option value="0" selected disabled>Seleccione Tipo identificación</option>
                    <option value="13">Cedula de ciudadania</option>
                    <option value="21">Cedula de extranjería</option>
                    <option value="32">Tarjeta de identidad</option>
                    <option value="31">Nit</option>
                </select>
                <span>*</span>
            </div>

            <div class="col-sm">
                <div class="d-flex align-items-center">
                    <input formControlName="cliente_identificacion" class="form-control input-registro" type="number" placeholder="Numero de documento">
                    <span>*</span>
                </div>
                <div class="d-flex  justify-content-center" 
                     *ngIf="formCliente.get('cliente_identificacion')?.invalid && (formCliente.get('cliente_identificacion')?.dirty || formCliente.get('cliente_identificacion')?.touched)">
                   <!--  <small class="text-danger">{{ getValidationMessage('cliente_identificacion') }}</small> -->
                </div>
            </div>
        </div>

        <div>
            <div class="col-sm">
                <select formControlName='cliente_tipopersona' class="form-select input-registro mb-2" type="text">
                    <option value="0" selected disabled>Seleccione Tipo persona</option>
                    <option value="1">natural</option>
                    <option value="2">Juridica</option>
                </select>
                <span>*</span>
            </div>
            <div class="col-sm">
                <div class="d-flex align-items-center">
                    <input formControlName="prnombre" class="form-control input-registro mb-2" type="text" placeholder="Primer nombre" name="prnombre" (keyup)="validaCadena($event)">
                    <span>*</span>
                </div>
                <div class="d-flex  justify-content-center" 
                    *ngIf="formCliente.get('prnombre')?.invalid && (formCliente.get('prnombre')?.dirty || formCliente.get('prnombre')?.touched)">
                    <!-- <small class="text-danger">{{ getValidationMessage('prnombre') }}</small> -->
                </div>
            </div>
        </div>
        
        <div >
            <div class="col-sm">
                <input formControlName="sgdonombre" class="form-control input-registro mb-2" type="text" placeholder="Segundo nombre" name="sgdonombre" (keyup)="validaCadena($event)">
            </div>

            <div class="col-sm">
                <div class="d-flex align-items-center">
                    <input formControlName ="prapellido" class="form-control input-registro mb-2" type="text" name='cliente_apellido_1'  placeholder="Primer apellido" name="prapellido" (keyup)="validaCadena($event)">
                    <span>*</span>
                </div>
                <div class="d-flex  justify-content-center" 
                 *ngIf="formCliente.get('prapellido')?.invalid && (formCliente.get('prapellido')?.dirty || formCliente.get('prapellido')?.touched)">
               <!--  <small class="text-danger">{{ getValidationMessage('prapellido') }}</small> -->
            </div>
            </div>
        </div>    
        
        <div>
            <div class="col-sm">
                <div class="d-flex align-items-center">
                    <input formControlName="sgdoapellido" class="form-control input-registro mb-2" placeholder="Segundo apellido" name="sgdoapellido" (keyup)="validaCadena($event)">
                </div>
            </div>
            <div class="col-sm d-flex align-items-center">
                <select formControlName='cliente_departamento' class="form-select input-registro mb-2" type="text" (change)="cargarCiudadesDpto($event)">
                    <option value="0" selected disabled>Seleccione Departamento</option>
                    <option *ngFor="let d of dptos" [value]="d.departamento_codigo" >{{ d["departamento_nombre "] | titlecase }}</option>
                </select>
                <span>*</span>
            </div>
        </div>

        <div>
            <div class="col-sm">
                <div class="col-sm d-flex align-items-center">
                    <select formControlName='cliente_ciudad' class="form-select input-registro" type="text">
                        <option value="0" selected disabled>Seleccione ciudad</option>
                        <option *ngFor="let c of ciudades" [value]="c.ciudad_codigo" >{{ c.ciudad_nombre| titlecase }}</option>
                    </select>
                    <span>*</span>
                </div>
            </div>

            <div class="col-sm">
                <div class="d-flex align-items-center">
                    <input formControlName="email" class="form-control input-registro mb-2" type="email" placeholder="Correo electronico">
                    <span>*</span>
                </div>
                <div class="d-flex  justify-content-center" 
                     *ngIf="formCliente.get('email')?.invalid && (formCliente.get('email')?.dirty || formCliente.get('email')?.touched)">
                    <!-- <small class="text-danger">{{ getValidationMessage('email') }}</small> -->
                </div>
            </div>

        </div>

        <div>
            <div class="col-sm">
                <div class="d-flex align-items-center">
                    <input formControlName='direccion' class="form-control input-registro mb-2" type="text"  placeholder="Direccion de vivienda">
                    <span>*</span>
                </div>
                <div class="d-flex  justify-content-center" 
                    *ngIf="formCliente.get('direccion')?.invalid && (formCliente.get('direccion')?.dirty || formCliente.get('direccion')?.touched)">
                   <!--  <small class="text-danger">{{ getValidationMessage('direccion') }}</small> -->
                </div>
            </div>
            <div class="col-sm">
                <div class="d-flex align-items-center">
                    <input formControlName="cliente_celular" class="form-control input-registro mb-2" type="text" placeholder="Celular">
                    <span>*</span>
                </div>
                <div class="d-flex  justify-content-center" 
                    *ngIf="formCliente.get('cliente_celular')?.invalid && (formCliente.get('cliente_celular')?.dirty || formCliente.get('cliente_celular')?.touched)">
                   <!--  <small class="text-danger">{{ getValidationMessage('cliente_celular') }}</small> -->
                </div>
            </div>
        </div>

        <div class="bg-light">
            <div class="col-sm">
                <a routerLink="/checkout" class="btn btn-regreso btn-hover" >
                    Atrás
                </a>
            </div> 
            <div class="col-sm">
                <button class="btn btn-siguiente" [disabled]=!formCliente.valid (click)="confirmar()">
                    <span *ngIf="isloading"> <app-spinner color="text-light" /> </span>
                    <span *ngIf="!isloading" style="color: white;">Siguiente</span>
                </button>
            </div>
        </div> 
    </form>
</div>