import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FiltrosService } from 'src/app/services/filtros.service';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { msg } from 'src/app/interfaces/mensajes.interface';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnDestroy {

  onEventoHijo!: Function;
  @Output() enviarDato = new EventEmitter<any>();
  selectedFunction?: string; // Para almacenar el nombre de la función seleccionada
  grupos = [
    {
      id:"flexRadioDefault5",
      value:"05",
      name:"CARROCERÍA"
    },
    {
      id:"flexRadioDefault4",
      value:"04",
      name:"CHASIS"
    },
    {
      id:"flexRadioDefault8",
      value:"08",
      name:"EQUIPO ELÉCTRICO TRANSMISÍON DE POTENCIA"
    },
    {
      id:"flexRadioDefault9",
      value:"09",
      name:"HERRAMIENTAS Y ACCESORIOS"
    },
    {
      id:"flexRadioDefault7",
      value:"07",
      name:"INTERIORES Y EXTERIORES"
    },
    {
      id:"flexRadioDefault1",
      value:"01",
      name:"MOTOR"
    },
    {
      id:"flexRadioDefault6",
      value:"06",
      name:"SISTEMA AIRE ACONDICIONADO"
    },
    {
      id:"flexRadioDefault2",
      value:"02",
      name:"SISTEMA COMBUSTIBLE Y CONTROL MOTOR"
    },
    {
      id:"flexRadioDefault3",
      value:"03",
      name:"TRANSMISIÓN DE POTENCIA"
    },
  ];

  subgrupos: any[] = [];
  productos: any = [];
  productosTmp: any = [];
  grupo?:any;
  subgrupo?: any;
  nLinea?: any;
  mensajes = msg;
  loading = false;

  getSubgrupo$: Subscription = new Subscription();
  getProductos$: Subscription = new Subscription();

  constructor(private filtroService: FiltrosService, private busquedaService: BusquedaService, 
             private router: ActivatedRoute, private utilSrv: UtilService) {
    this.router.params.subscribe(params => {
      this.nLinea = (params['inputbusqueda']);
    });
  }
  
  obtenerSubgrupos(event: Event){
    const e = event.target as HTMLInputElement;
    this.nLinea = this.busquedaService.noLinea;
    this.grupo = e.value;
    this.enviarDato.emit([]);

    if( !this.nLinea ){
      return;
    }
    
    if( !this.grupo ){
      return;
    }

    const payload = {
      linea: this.nLinea, 
      grupo: this.grupo
    }

    this.loading = true;

    this.getSubgrupo$ = this.filtroService.getSubgrupoS(payload).subscribe({
      next: (resp: any)=>{
        this.loading = false;
        if(resp.datos.retorno.length > 0){

          const arrSubgrupo =  (resp.datos.retorno).reduce((acc:any, reg:any)=>{
            const existe = acc.some((p:any) => p.referencia_subgruporepuesto === reg.referencia_subgruporepuesto); 
            if (!existe) {
              let data =  {
                referencia_subgruporepuesto: reg.referencia_subgruporepuesto,
                referencia_subgruporepuesto_d: reg.referencia_subgruporepuesto_d
              }
              acc.push(data);
            }
            return acc;
          }, []);

          arrSubgrupo.sort((a: any, b:any) => a.referencia_subgruporepuesto_d.localeCompare(b.referencia_subgruporepuesto_d));
          this.subgrupos = arrSubgrupo;
        }else{
          this.utilSrv.showAlert(msg.MSG_SIN_REGISTROS,'Advertencia!','warning');
        }

      },
      error: (error)=>{
        this.loading = false;
        console.log('error',error);
      }
    });
  }

  obtenerProductos(event: Event){
    const e = event.target as HTMLInputElement;
    this.enviarDato.emit([]);
    const payload = {
      linea: this.nLinea,
      grupo: this.grupo,
      subgrupo: e.value
    }
    this.productosTmp = [];
    this,this.loading = true;
    this.getProductos$ = this.filtroService.getProductos(payload).subscribe({
      next: (resp:any)=>{
        this,this.loading = false;
        if(resp.datos.retorno.length > 0){
          this.productos = resp.datos.retorno;
          this.productosTmp = this.productos;
          this.enviarDato.emit(this.productos);
        }else{
          this.utilSrv.showAlert(msg.MSG_SIN_REGISTROS,'Advertencia!','warning');
        }
      },
      error:(error)=>{
        this,this.loading = false;
        console.log('error',error);
      }
    });

  }


  getProducto(event: Event){
    const e = event.target as HTMLInputElement;
    const ref = e.value;
    const resp = this.productosTmp.find((p: any)=> p.referencia_codigo == ref);
    this.enviarDato.emit([resp]);
  }

  ngOnDestroy(): void {
    if(this.getSubgrupo$){
      this.getSubgrupo$.unsubscribe();
    }
    
    if(this.getProductos$){
      this.getProductos$.unsubscribe();
    }
  }

}
