<div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h2>Restablecer Contraseña</h2>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group mb-3">
            <div class="d-flex align-items-center">
                <input type="password" formControlName="password" class="form-control" placeholder="Ingresa tu nueva contraseña">
                <span class="text-danger">*</span>
            </div>
            <div *ngIf="resetPasswordForm.get('password')?.invalid && resetPasswordForm.get('password')?.touched" class="text-danger">
                <small>{{ getValidationMessage('password') }}</small>
            </div>
          </div>
          
          <div class="form-group mb-3">
            <div class="d-flex align-items-center">
                <input type="password" id="confirmPassword" name="confirmPassword" formControlName="confirmPassword" class="form-control" placeholder="Confirma tu nueva contraseña">
                <span class="text-danger">*</span>
            </div>
            <div  *ngIf="resetPasswordForm.get('confirmPassword')?.invalid && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched)"  class="text-danger">
              <small>{{ getValidationMessage('confirmPassword') }}</small>
            </div>
          </div>

          <button class="btn btn-agendamiento" [disabled]=!resetPasswordForm.valid>
            <span *ngIf="isloading"> <app-spinner color="text-light" /> </span>
            <span *ngIf="!isloading">Recuperar</span>
          </button>
         
        </form>
      </div>
    </div>
  </div>
  
